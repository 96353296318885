import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import injectSheet from "react-jss";
import {API} from "../../api/Api";
import MESSAGES from "./Messages";


const styles = {
    root: { width: '100%' },
    centerDiv: { width: '320px' },
    textField: { marginLeft: '2px', marginRight: '2px', width: '100%'},
    registrationButton: { width: '100%', color: '#FFFFFF', backgroundColor: '#5cb85c !important', '&:hover': {
            backgroundColor: '#509f50 !important',
        }}
};

class Form extends Component {

    constructor(props){
        super(props);
        this.state = {
            loginRequest: {
                authName: '',
                authPassword: '',
                email: '',
                role: 'admin',
            },
            jwtToken :""
        };

    }

    componentDidMount(){
        let jwtToken = new URLSearchParams(this.props.location.search).get("jwtToken")
        if(jwtToken) {
            localStorage.setItem("jwtToken", jwtToken);
            localStorage.setItem("jwtTokenMinutes", "59");
            localStorage.setItem("jwtTokenSeconds", "59");

            API.login.prv.getAccount()
                .then( response => {
                    let data  = response.data
                    localStorage.setItem("role", data.role);
                    localStorage.setItem("userId", data.email);
                    window.location.href = "/";
                }).catch(console.log)
        }
    }

    actionCreate () {
        API.login.pub.post(this.state.loginRequest).then(response => {


            var arr = window.location.href.split("/");
            var url = arr[0] + "//" + arr[2]

            var token = "/#/login?jwtToken=" + response.data
            this.state.jwtToken = url + token
            this.forceUpdate()
        }).catch(console.log);
    }

    actionLogin () {
        window.location.href = this.state.jwtToken
        window.location.reload()
    }

    render() {

        const styles = this.props.classes

        return (
            <div align="center" className={styles.root}>
                <div className={styles.centerDiv}>
                    <h2>Herzlich willkommen</h2>

                    <h4>Token erstellen für:</h4>
                    <div style={{border: '2px solid gray', borderRadius: '5px', paddingLeft: 10, paddingRight: 10}}>
                        <TextField
                            label={MESSAGES.email}
                            className={styles.textField}
                            value={this.state.loginRequest.email}
                            onChange={(e) => {
                                this.state.loginRequest.email = e.target.value
                                this.forceUpdate()
                            }}
                            margin="normal"
                        />
                        <div style={{textAlign: 'left', paddingBottom: 10}}>
                            <InputLabel style={{fontSize: 12, marginBottom: 4}}>{MESSAGES.role}</InputLabel>
                            <Select
                                value={this.state.loginRequest.role}
                                className="valueComponent"
                                onChange={(e) => {
                                    this.state.loginRequest.role = e.target.value
                                    this.forceUpdate()
                                }}
                                inputProps={{
                                    name: 'age',
                                    id: 'age-simple',
                                }}>
                                <MenuItem value={'admin'}>Admin</MenuItem>
                                <MenuItem value={'member'}>Mitglied</MenuItem>
                            </Select>
                        </div>
                    </div>

<br/>

                    <TextField
                        label={MESSAGES.authName}
                        className={styles.textField}
                        value={this.state.loginRequest.authName}
                        onChange={(e) => {
                            this.state.loginRequest.authName = e.target.value
                            this.forceUpdate()
                        }}
                        margin="normal"
                    />
                    <TextField
                        label={MESSAGES.authPassword}
                        type="password"
                        className={styles.textField}
                        value={this.state.loginRequest.authPassword}
                        onChange={(e) => {
                            this.state.loginRequest.authPassword = e.target.value
                            this.forceUpdate()
                        }}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                this.actionLogin();
                            }
                        }}
                        margin="normal"
                    />

                    <div>
                        <Button style={{textDecoration: "none", float: "right"}} onClick={() => this.actionCreate()}>Token erzeugen</Button>
                    </div>

                    {this.state.jwtToken && (
                        <div>
                            <TextField
                                label={MESSAGES.token}
                                className={styles.textField}
                                value={this.state.jwtToken}
                                margin="normal"
                            />
                            <Button style={{textDecoration: "none", float: "right"}} onClick={() => this.actionLogin()}>Anmelden</Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default injectSheet(styles)(Form);
