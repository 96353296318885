import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import PhotoIcon from '@material-ui/icons/Photo';

import {Link} from "react-router-dom";
import injectSheet from "react-jss";
import {API, isAdmin, isLogin, isMember, isUser} from "../../api/Api";
import MESSAGES from "./Messages";
import Switch from "@material-ui/core/Switch";

const styles = {
    filterTextField: { marginLeft: '2px', flex: '1' },
    filterIconButton: { padding: '10px' },
    addButton: { float:  'right', background: '#FFFFFF', color: '#000000' },
    countsDiv: { background: '#1976d2', color: '#FFFFFF', padding: '5px', marginBottom: '5px', borderRadius: '4px', boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)' }
};

class List extends Component {

    constructor(props){
        super(props);
        this.state = {
            datas : [],
            filter: {
                text: "",
                sort: "justiceReferenceNumber",
                sortType: "ASC",
                deleted: false
            },
            counts: {}
        };
        this.onSearchButtonClick = this.onSearchButtonClick.bind(this);
    }

    componentDidMount(){
        this.onSearchButtonClick(this.state.filter);
    }

    onSearchButtonClick() {
        /*
        if(!isLogin) {
            API.judgment.pub.getCounts(this.state.filter)
                .then( response => {
                    this.setState({ counts : response.data })
                }).catch(console.log);
        } else {
            API.judgment.prv.get(this.state.filter)
                .then( response => {
                    this.setState({ datas : response.data })
                }).catch(console.log);
        }
         */
        API.judgment.pub.get(this.state.filter)
            .then( response => {
                this.setState({ datas : response.data })
            }).catch(console.log);
    }

    onTitleClick(sort) {
        if(this.state.filter.sort == sort &&this.state.filter.sortType == 'ASC' ) {
            this.state.filter.sortType = 'DESC'
        } else {
            this.state.filter.sortType = 'ASC'
        }
        this.state.filter.sort = sort

        this.onSearchButtonClick()
    }

    render() {
        const styles = this.props.classes

        return (
            <div>
                <h3 style={{margin: 0}}>Herzlich wilkommen zur Urteils-Datenbank</h3>
                <div>
                    <TextField
                        value={this.state.filter.text}
                        onChange={(event) => {
                            this.state.filter.text = event.target.value;
                            this.forceUpdate();
                        }}
                        onKeyDown={this.onSearchButtonClick}
                        className={styles.filterTextField}
                        placeholder={MESSAGES.search}
                        inputProps={{ 'aria-label': 'search google maps' }}
                    />
                    <IconButton
                        className={styles.filterIconButton}
                        aria-label="search"
                        onClick={this.onSearchButtonClick}>
                        <SearchIcon />
                    </IconButton>
                    { isAdmin&& (
                        <span style={{paddingLeft: 20}}>
                            Gelöschte <Switch
                            id="switchBlackList"
                            checked={this.state.filter.deleted}
                            onChange={(e) => {
                                this.state.filter.deleted = e.target.checked
                                this.onSearchButtonClick()
                            }}
                        />
                        </span>
                    )}
                    { isAdmin && (
                        <Link to={'/judgments/create'} ><Button className={styles.addButton}>+ {MESSAGES.add}</Button></Link>
                    )}


                </div>
                { this.state.counts.publicCount != undefined && (
                    <div className={styles.countsDiv}> Sie sehen {this.state.counts.publicCount + ' von ' + this.state.counts.privateCount} möglichen Ergebnisse. Melden Sie sich in <a style={{color: '#FFFFFF'}} href="https://portal.degeuk.org/">Customer Portal</a> an und folgen Sie den Link um mehr zu sehen!</div>
                )}
                <table className="dbsTable">
                    <tr>
                        <th className="dbsTableHeaderCell"></th>
                        <th className="dbsTableHeaderCell" onClick={() => this.onTitleClick('justiceReferenceNumber')}>Gericht Aktenzeichen {this.state.filter.sort == 'justiceReferenceNumber' && ('[' + this.state.filter.sortType + ']')}</th>
                        <th className="dbsTableHeaderCell" onClick={() => this.onTitleClick('date')}>Gericht Datum {this.state.filter.sort == 'date' && ('[' + this.state.filter.sortType + ']')}</th>
                        <th className="dbsTableHeaderCell" onClick={() => this.onTitleClick('shortText')}>Kurz Text {this.state.filter.sort == 'shortText' && ('[' + this.state.filter.sortType + ']')}</th>
                        <th className="dbsTableHeaderCell" onClick={() => this.onTitleClick('entryAt')}>Eingetragen am {this.state.filter.sort == 'entryAt' && ('[' + this.state.filter.sortType + ']')}</th>
                        <th className="dbsTableHeaderCell"></th>
                    </tr>
                    {this.state.datas.map((data, index) => (
                        <tr className={index % 2 ? 'dbsTableRow1': 'dbsTableRow2'}>
                            <td className="dbsTableContentCell">{ data.contentAvaileble && (<PhotoIcon/>)}</td>
                            <td className="dbsTableContentCell">{data.justiceReferenceNumber}</td>
                            <td className="dbsTableContentCell">{data.date}</td>
                            <td className="dbsTableContentCell">{data.shortText}</td>
                            <td className="dbsTableContentCell">{data.entryAt}</td>
                            <td className="dbsTableContentCell" style={{textAlign: 'right'}}><Link to={'/judgments/id/' + data.id} style={{ color: '#000000' }}>{MESSAGES.show}</Link></td>
                        </tr>
                    ))}
                </table>
            </div>

        );
    }
}

export default injectSheet(styles)(List);