import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField/TextField";
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Button from "@material-ui/core/Button/Button";


import PreviewsDialog from '../PreviewsDialog'
import {Link} from "react-router-dom";
import EvenEmitter from "../EvenEmitter";
import {API, isAdmin, isLogin, isMember} from "../../api/Api";
import MESSAGES from "./Messages";

class Details extends Component {

    constructor(props){
        super(props);
        this.state = {
            create: false,
            open: false,
            data: {
                id: Number,
                justice: String,
                justiceReferenceNumber: String,
                date: String,
                shortText: String,
                longText: String,
                entryAt: String,
                tags: String,
                common: false,
                content: null,
                contentAvaileble: Boolean
            },
            previews: []};


    }

    handleSetState(cat, key, val) {
        const category = {...this.state[cat]};
        if (typeof key === 'string') {
            category[key] = val;
        } else {
            key.map((item, index) => {
                category[item] = val[index];
            });
        }
        this.setState({[cat]: category});
    }

    componentDidMount(){
        if(this.props.match.params.uuid == null){
            this.setState({create: true});
        } else {
            this.load()
        }
    }

    load(){
        API.judgment.pub.getOne(this.props.match.params.uuid)
            .then( response => {
                this.setState({data: response.data});

                if(this.state.data.contentAvaileble) {
                    API.judgment.pub.getContent(this.props.match.params.uuid)
                        .then( response2 => {
                            this.setState(response2.data);
                        })
                        .catch(console.log);
                }
            })
            .catch(console.log);
    }

    render() {

        if (!isAdmin && !isMember && this.state.data.blockCommonLink) {
            return (
                <div>
                    <div>Dieser Link ist nur für angemeldete Mitglieder sichtbar!</div>
                    <div>This link is only visible for registered members!</div>
                </div>
            )
        } else {
            return (
                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={3}>
                            {this.state.previews.map(preview => {
                                return <div>
                                    <img
                                        className="content"
                                        src={"data:image/png;base64," + preview.content}
                                        alt="Random first slide"
                                        onClick={() => {
                                            preview.show = true;
                                            this.setState({open: true});
                                        }}/>
                                    <PreviewsDialog
                                        open={preview.show}
                                        preview={preview.content}
                                        onClose={() => {
                                            preview.show = false;
                                            this.setState({open: false});
                                        }} />
                                </div>
                            })}
                            { isAdmin && this.state.data.contentAvaileble === false && (
                                <input type="file" name="file" onChange={(event => {
                                    API.judgment.prv.upload(event.target.files[0], this.state.data.id).then( response => {
                                        EvenEmitter.dispatch("NOTIFICATION_SUCCESS_MESSAGE", {message: 'Dein Image wurde erfolgreich hochgeladen!'})
                                        this.load()
                                    })
                                })}/>
                            )}

                            { isAdmin && this.state.data.contentAvaileble === true && (
                                <Button onClick={() => {
                                    API.judgment.prv.deleteContent(this.state.data.id).then(response => {
                                        EvenEmitter.dispatch("NOTIFICATION_SUCCESS_MESSAGE", {message: 'Dein Image wurde erfolgreich gelöscht!'})
                                        this.load()
                                    })
                                }}>{MESSAGES.delete}</Button>
                            )}

                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Grid container spacing={1}>
                                {this.state.create == false && (
                                    <Grid item xs={12}>
                                        <TextField
                                            id="textFieldId"
                                            disabled={true}
                                            className="valueComponent"
                                            label="Id"
                                            value={this.state.data.id}
                                        />
                                    </Grid>
                                )}
                                {this.state.create == false && (
                                    <Grid item xs={12}>
                                        <TextField
                                            id="textFieldShareLink"
                                            disabled={true}
                                            className="valueComponent"
                                            label="Share Link"
                                            value={"https://certificate.degeuk.org/#/judgments/id/" + this.state.data.id}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="textFieldJustice"
                                        disabled={!isAdmin}
                                        className="valueComponent"
                                        label="Gericht"
                                        value={this.state.data.justice}
                                        onChange={(e) => this.handleSetState('data', 'justice', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="textFieldJusticeReferenceNumber"
                                        disabled={!isAdmin}
                                        className="valueComponent"
                                        label="Gericht Aktenzeichen"
                                        value={this.state.data.justiceReferenceNumber}
                                        onChange={(e) => this.handleSetState('data', 'justiceReferenceNumber', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}></Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="textFieldDate"
                                        type="date"
                                        disabled={!isAdmin}
                                        className="valueComponent"
                                        label="Datum"
                                        value={this.state.data.date}
                                        onChange={(event) => {
                                            this.state.data.date = event.target.value;
                                            this.forceUpdate();
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="textFieldShortText"
                                        disabled={!isAdmin}
                                        className="valueComponent"
                                        label="Kurztext"
                                        value={this.state.data.shortText}
                                        onChange={(e) => this.handleSetState('data', 'shortText', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="textFieldLongText"
                                        disabled={!isAdmin}
                                        className="valueComponent"
                                        label="Langtext"
                                        multiline
                                        value={this.state.data.longText}
                                        onChange={(e) => this.handleSetState('data', 'longText', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="textField"
                                        disabled={!isAdmin}
                                        className="valueComponent"
                                        label="Schlagwörter"
                                        value={this.state.data.tags}
                                        onChange={(e) => this.handleSetState('data', 'tags', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="textFieldEntryAt"
                                        type="date"
                                        disabled={!isAdmin}
                                        className="valueComponent"
                                        label="Eintrag am"
                                        value={this.state.data.entryAt}
                                        onChange={(event) => {
                                            this.state.data.entryAt = event.target.value;
                                            this.forceUpdate();
                                        }}
                                    />
                                </Grid>
                                {isAdmin && (
                                    <Grid item xs={12} md={6}>
                                        <div
                                            className="MuiFormControl-root MuiTextField-root valueComponent MuiFormControl">
                                            <label
                                                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
                                                data-shrink="true" htmlFor="textFieldJustice">Öffentlich</label>
                                            <div
                                                className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                                                <Switch
                                                    id="switchCommon"
                                                    checked={this.state.data.common}
                                                    onChange={(e) => this.handleSetState('data', 'common', e.target.checked)}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                            <div style={{float: "right"}}>
                                <Link to={'/judgments'} style={{textDecoration: 'none'}}><Button>{MESSAGES.cancel}</Button></Link>
                                {this.state.create == true && (
                                    <div style={{display: 'inline'}}>
                                        <Button onClick={() => {
                                            API.judgment.prv.post(this.state.data).then(response => {
                                                if(response != null) {
                                                    this.setState({data: response.data});
                                                    window.location.href = '/#/judgments/id/' + response.data.id;
                                                    EvenEmitter.dispatch("NOTIFICATION_SUCCESS_MESSAGE", {message: 'Dein Urteil wurde erfolgreich gespeichert!'})
                                                }
                                            })
                                        }}>{MESSAGES.save}</Button>
                                    </div>
                                )}
                                {(isAdmin || isMember) && this.state.data.contentAvaileble == true && this.state.data.contentAvaileble && (
                                    <div style={{display: 'inline'}}>
                                        <Button  onClick={() => {
                                            API.judgment.prv.download(this.state.data.id)
                                        }}>{MESSAGES.download}</Button>
                                    </div>
                                )}

                                {isAdmin && this.state.create == false && (
                                    <div style={{display: 'inline'}}>
                                        <Button onClick={() => {
                                            const r = window.confirm( 'Are you sure you want to delete?' );
                                            if (r === true) {
                                                API.judgment.prv.delete(this.state.data.id).then(response => {
                                                    EvenEmitter.dispatch("NOTIFICATION_SUCCESS_MESSAGE", {message: 'Dein Zertifikat wurde erfolgreich gelöscht!'})
                                                    window.location.href = '/#/judgments';
                                                })
                                            }
                                        }}>{MESSAGES.delete}</Button>
                                        <Button onClick={() => {
                                            API.judgment.prv.post(this.state.data).then(response => {
                                                if(response != null) {
                                                    this.setState({data: response.data});
                                                    window.location.href = '/#/judgments/id/' + response.data.id ;
                                                    EvenEmitter.dispatch("NOTIFICATION_SUCCESS_MESSAGE", {message: 'Dein Urteil wurde erfolgreich gespeichert!'})
                                                }
                                            })
                                        }}>{MESSAGES.update}</Button>
                                    </div>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }
}

export default Details;