exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Template_headerImageDiv__22oJ7 {\n    position: fixed;\n    z-index: 2;\n    padding-top: 30px;\n}\n\n.Template_buttons__1slfV {\n    position: fixed;\n    width: 100%;\n    max-width: 1280px;\n    z-index: 3;\n    float: right;\n}\n\n.Template_title__3e39k {\n    color: #FFFFFF;\n    font-size: 32px;\n    position: fixed;\n    z-index: 2;\n    margin: 0px;\n    padding-left: 130px;\n    padding-right: 40px;\n    padding-top: 40px;\n}\n\n.Template_frontContainer__j-T9e {\n    position: fixed;\n    z-index: 1;\n    width: 100%;\n    background: #333333;\n    box-shadow: 0 2px 5px 0 rgba(0,0,0,.3);\n}\n\n.Template_backgroundContainer__3p8rQ {\n    width: 100%;\n    background: #FFFFFF;\n}", ""]);

// Exports
exports.locals = {
	"headerImageDiv": "Template_headerImageDiv__22oJ7",
	"buttons": "Template_buttons__1slfV",
	"title": "Template_title__3e39k",
	"frontContainer": "Template_frontContainer__j-T9e",
	"backgroundContainer": "Template_backgroundContainer__3p8rQ"
};