import React, {Component} from 'react';

import EvenEmitter from "../../EvenEmitter";

class Form extends Component {

    EVENT_HTTP_ERROR_RESPONSE = "NOTIFICATION_HTTP_ERROR_RESPONSE"

    EVENT_SUCCESS_MESSAGE = "NOTIFICATION_SUCCESS_MESSAGE"

    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            type: null,
            event: null
        }
        EvenEmitter.subscribe(this.EVENT_HTTP_ERROR_RESPONSE, (event) => {
            this.state.notifications.push(this.notificateHttpErrorResponse(event))
            this.forceUpdate()
            let interval = setInterval(() => this.actionRemoveFirstNotification(interval), 6000)
        })
        EvenEmitter.subscribe(this.EVENT_SUCCESS_MESSAGE, (event) => {
            this.state.notifications.push(this.notificateSuccessMessage(event))
            this.forceUpdate()
            let interval = setInterval(() => this.actionRemoveFirstNotification(interval), 6000)
        })
    }


    componentDidMount() {

    }

    actionRemoveFirstNotification(interval){
        this.state.notifications.splice(0, 1)
        this.forceUpdate()
        clearInterval(interval)
    }

    notificateHttpErrorResponse(event) {
        return (
            <div style={{background:'#f8b7bd', borderRadius: '3px', opacity: '0.95', boxShadow: '0 3px .429em 0 rgba(0,0,0,0.16)', margin: '5px', padding: '10px', wordWrap: 'break-word'}}>
                <div><b>Error: {event.error} ({event.status})</b></div>
                <div>Path: {event.path}</div><br/>
                <div>Message:<br/>{event.message}</div>
            </div>
        )
    }

    notificateSuccessMessage(event) {
        return (
            <div style={{background:'#b7d8b7', borderRadius: '3px', opacity: '0.95', boxShadow: '0 3px .429em 0 rgba(0,0,0,0.16)', margin: '5px', padding: '10px', wordWrap: 'break-word'}}>
                <div><b>Erfolgreich:</b></div><br/>
                <div>{event.message}</div>
            </div>
        )
    }

    render() {


        return (
            <div style={{position: 'relative ', float: 'right', width:'400px'}}>
                <div style={{position: 'absolute', zIndex: '1000', width:'395px'}}>
                    {this.state.notifications.map((notification, index) => (
                        <div>
                            <div
                                style={{position: 'relative', float: 'right', paddingRight: '15px', zIndex: '1001'}}
                                onClick={() => {
                                this.state.notifications.splice(index, 1)
                                this.forceUpdate()
                            }}>x</div>
                            {notification}
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default Form;