export let MESSAGES = {
    search: "suchen",
    add: 'Hinzufügen',
    show: 'ANZEIGEN',
    cancel: 'Abbruch',
    delete: 'Löschen',
    update: 'Speichern',
    save: 'Speichern',
    download: 'Herunterladen'
}
export default MESSAGES;