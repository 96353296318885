import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

class RedirectOldPath extends Component {

    constructor(props){
        super(props);
        this.state = {
            hash: String,
            data: String,
            id: String
        };


    }

    // componentWillMount(){}
    componentDidMount(){
        var pathArray = window.location.href.split('/');
        var lastPath = pathArray[pathArray.length-1];
        var hashPathArray = window.location.hash.split('/');
        this.setState({ lastPath: lastPath, hash: hashPathArray[0],type : hashPathArray[1], id : hashPathArray[2]});
    }
    // componentWillUnmount(){}

    // componentWillReceiveProps(){}
    // shouldComponentUpdate(){}
    // componentWillUpdate(){}
    // componentDidUpdate(){}

    render() {
        return (
            <div>
                {this.state.hash === '#' && this.state.type === 'details' && (
                    <Redirect push to={"/certificates/id/" + this.state.id}/>
                )}
                {this.state.hash === '#' && this.state.type === 'home' && (
                    <Redirect push to={"/home"}/>
                )}
                {this.state.hash === '#' && this.state.lastPath === '' &&  (
                    <Redirect push to={"/home"}/>
                )}
            </div>

        );
    }
}

export default RedirectOldPath;