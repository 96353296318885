import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField/TextField";
import Switch from '@material-ui/core/Switch';
import Button from "@material-ui/core/Button/Button";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import PhotoIcon from '@material-ui/icons/Photo';

import {Link} from "react-router-dom";
import {API, isAdmin, isLogin, isMember} from "../../api/Api";
import injectSheet from "react-jss";
import MESSAGES from "./Messages";

const styles = {
    filterTextField: { marginLeft: '2px', flex: '1' },
    filterIconButton: { padding: '10px' },
    addButton: { float:  'right', background: '#FFFFFF', color: '#000000' },
    countsDiv: { background: '#1976d2', color: '#FFFFFF', padding: '5px', marginBottom: '5px', borderRadius: '4px', boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)' }
};

class CertificateList extends Component {

    constructor(props){
        super(props);
        this.state = {
            datas : [],
            filter: {
                text: "",
                sort: "certificateId",
                sortType: "ASC"
            }
        };

        this.onSearchButtonClick = this.onSearchButtonClick.bind(this);

    }
    componentDidMount(){
        this.state.filter.black  = true
        this.onSearchButtonClick();
    }

    onSearchButtonClick() {
        API.blackCertificate.pub.get(this.state.filter)
            .then(response => {
                this.setState({ datas : response.data})
            }).catch(console.log);
    }

    onTitleClick(sort) {
        if(this.state.filter.sort == sort &&this.state.filter.sortType == 'ASC' ) {
            this.state.filter.sortType = 'DESC'
        } else {
            this.state.filter.sortType = 'ASC'
        }
        this.state.filter.sort = sort

        this.onSearchButtonClick()
    }

    render() {

        const styles = this.props.classes

        return (
            <div>
                <h3  style={{margin: 0}}>Herzlich wilkommen zur Schwarze Liste der Geräte</h3>
                <div>
                    <TextField
                        value={this.state.filter.text}
                        onChange={(event) => {
                            this.state.filter.text = event.target.value;
                            this.forceUpdate();
                        }}
                        onKeyDown={this.onSearchButtonClick}
                        className={styles.filterTextField}
                        placeholder={MESSAGES.search}
                        inputProps={{ 'aria-label': 'search google maps' }}
                    />
                    <IconButton
                        className={styles.filterIconButton}
                        aria-label="search"
                        onClick={this.onSearchButtonClick}
                    >
                        <SearchIcon />
                    </IconButton>
                </div>
                <table className="dbsTable">
                    <tr>
                        <th className="dbsTableHeaderCell"></th>
                        <th className="dbsTableHeaderCell" onClick={() => this.onTitleClick('certificateId')}>Certificate-ID {this.state.filter.sort == 'certificateId' && ('[' + this.state.filter.sortType + ']')}</th>
                        <th className="dbsTableHeaderCell" onClick={() => this.onTitleClick('testInstitute')}>Testinstitut {this.state.filter.sort == 'testInstitute' && ('[' + this.state.filter.sortType + ']')}</th>
                        <th className="dbsTableHeaderCell" onClick={() => this.onTitleClick('issuedFor')}>Ausgestellt für {this.state.filter.sort == 'issuedFor' && ('[' + this.state.filter.sortType + ']')}</th>
                        <th className="dbsTableHeaderCell" onClick={() => this.onTitleClick('modelName')}>Modellname {this.state.filter.sort == 'modelName' && ('[' + this.state.filter.sortType + ']')}</th>
                        <th className="dbsTableHeaderCell" onClick={() => this.onTitleClick('status')}>Status {this.state.filter.sort == 'status' && ('[' + this.state.filter.sortType + ']')}</th>
                        <th className="dbsTableHeaderCell"></th>
                    </tr>
                    {this.state.datas.map((data, index) => (
                        <tr className={index % 2 ? 'dbsTableRow1': 'dbsTableRow2'}>
                            <td className="dbsTableContentCell">{ data.contentAvaileble && (<PhotoIcon/>)}</td>
                            <td className="dbsTableContentCell">{data.certificateId}</td>
                            <td className="dbsTableContentCell">{data.testInstitute}</td>
                            <td className="dbsTableContentCell">{data.issuedFor}</td>
                            <td className="dbsTableContentCell">{data.modelName}</td>
                            <td className="dbsTableContentCell">{data.status + ''}</td>
                            <td className="dbsTableContentCell" style={{textAlign: 'right'}}><Link to={'/certificates/id/' + data.id} style={{ color: '#000000' }}>{MESSAGES.show}</Link></td>
                        </tr>
                    ))}
                </table>
            </div>

        );
    }
}

export default injectSheet(styles)(CertificateList);