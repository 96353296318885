import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import ReceiptIcon from '@material-ui/icons/Receipt';
import GavelIcon from '@material-ui/icons/Gavel';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {isLogin} from "../../api/Api";
import injectSheet from "react-jss";
import Button from "@material-ui/core/Button";

const styles = {
    navGrid: { textAlign: 'center', height: '150px', paddingTop: '22px'},
    centerDiv: { width: '320px' },
    registrationButton: { width: '100%', color: '#FFFFFF', backgroundColor: '#5cb85c !important', '&:hover': {
            backgroundColor: '#509f50 !important',
        }}
};

class Home extends Component {

    constructor(props){
        super(props);
        this.state = {
        };
    }

    render() {

        const styles = this.props.classes


        return (
            <div>
                <h3  style={{margin: 0}}>Home</h3>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Link to="/certificates" style={{ textDecoration: 'none', color: 'white' }}>
                            <div className={styles.navGrid+ " darkPaper"}>
                                <ReceiptIcon style={{ fontSize: 100 }}/>
                                <br/>
                                <label>Zertifikat</label>
                            </div>
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Link to="/judgments" style={{ textDecoration: 'none', color: 'white' }}>
                            <div className={styles.navGrid+ " darkPaper"}>
                                <GavelIcon style={{ fontSize: 100 }}/>
                                <br/>
                                <label>Urteil</label>
                            </div>
                        </Link>
                    </Grid>
                </Grid>

                {isLogin && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Link to="/home"  style={{ textDecoration: 'none', color: 'white' }}>
                                <div className={styles.navGrid+ " darkPaper"}
                                     onClick={() => {
                                         localStorage.clear();
                                         window.location.reload()
                                     }}>
                                    <ExitToAppIcon style={{ fontSize: 100 }}/>
                                    <br/>
                                    <label>Abmelden</label>
                                </div>
                            </Link>
                        </Grid>
                    </Grid>
                )}
                {!isLogin && (
                    <div align="center" className={styles.root}>
                        <br/>
                        <div className={styles.centerDiv}>
                            <h4 style={{marginBottom: 4}}>Join our community now! </h4>
                            <div>Um alle Inhalte zu sehen, müssen Sie Verbandsmitglied werden und den Link über unser Mitglieder-Portal nutzen.</div>
                            <br/>
                            <a style={{textDecoration: 'none'}} href="https://degeuk.org/mitglied-werden/">
                                <Button className={styles.registrationButton}>Mitglied werden</Button>
                            </a>
                            oder direkt zum
                            <a style={{textDecoration: 'none'}} href="https://portal.degeuk.org/">
                                <Button className={styles.registrationButton}>Mitglieder-Portal</Button>
                            </a>
                        </div>
                    </div>
                )}
            </div>

        );
    }
}

export default injectSheet(styles)(Home);
