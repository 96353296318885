import React, {Component} from 'react';
import styles from './Template.module.css';
import {Link} from "react-router-dom";
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import ReceiptIcon from '@material-ui/icons/Receipt';
import GavelIcon from '@material-ui/icons/Gavel';
import NotificationForm from './notification/Form'
import {isLogin} from "../../api/Api";

class Template extends Component {

    constructor(props){
        super(props);
        this.state = {
            height: 0,
            minutes: localStorage.getItem("jwtTokenMinutes"),
            seconds: localStorage.getItem("jwtTokenSeconds")
        };


    }

    componentDidMount(){
        window.addEventListener('resize', this.resize)
        this.setState({height: this.refs.appBarContainer.clientHeight})

        if(isLogin) {
            let interval = setInterval(() => {
                if(localStorage.getItem("jwtTokenSeconds") <= 0 ){
                    if(localStorage.getItem("jwtTokenMinutes") <= 0){
                        this.onLogout()
                    } else {
                        var minutes = localStorage.getItem("jwtTokenMinutes") -1
                        localStorage.setItem("jwtTokenMinutes", minutes + '');
                        localStorage.setItem("jwtTokenSeconds", 59 + '');
                    }
                } else {
                    var seconds = localStorage.getItem("jwtTokenSeconds") -1
                    localStorage.setItem("jwtTokenSeconds", seconds + '');
                }
                this.setState({minutes: localStorage.getItem("jwtTokenMinutes"), seconds: localStorage.getItem("jwtTokenSeconds")})
            }, 1000)
        }
    }

    onLogout() {
        localStorage.clear()
        window.location.replace("/");
    }

    resize = () => {
        this.setState({height: this.refs.appBarContainer.clientHeight})
    }

    render() {

        return (
            <div align="center">
                <div className={styles.frontContainer} style={{height: this.state.height}}/>
                <div style={{width: "1280px"}} align="left">


                    <div className={styles.buttons}>
                        <NotificationForm/>
                        <div style={{float: 'right'}}>

                            {isLogin === false && (
                                <Link to="/login" style={{ textDecoration: 'none', color: 'white' }}>
                                    <Button style={{ color: '#FFFFFF' }}> </Button>
                                </Link>
                            )}
                            <Link to="/home" style={{ color: 'white' }}><Button style={{ color: '#FFFFFF' }}><HomeIcon/></Button></Link>
                            <Link to="/certificates" style={{ color: 'white' }}><Button style={{ color: '#FFFFFF' }}><ReceiptIcon/></Button></Link>
                            <Link to="/judgments" style={{ color: 'white' }}><Button style={{ color: '#FFFFFF' }}><GavelIcon/></Button></Link>

                            {isLogin && (
                                <Link to="/home" style={{ textDecoration: 'none', color: 'white' }}>
                                    <Button style={{ color: '#FFFFFF' }} onClick={() => this.onLogout()
                                    }>LOGOUT ({this.state.minutes}:{this.state.seconds <= 9 ? '0' : ''}{this.state.seconds})</Button>
                                </Link>
                            )}
                        </div>
                    </div>
                    <div ref="appBarContainer" className={styles.title}>
                        DEGEUK - Deutsche Gesellschaft f&uuml;r EU Konformit&auml;t e.V.
                    </div>
                    <div className={styles.headerImageDiv}>
                        <a href="https://degeuk.org">
                            <img src="http://degeuk.org/wp-content/uploads/2019/02/logo_transparent2.png" alt="" width="110px" />
                        </a>
                    </div>

                </div>

                <div className={styles.backgroundContainer} style={{height: this.state.height}}>sdfsf {this.state.height}</div>
            </div>
        );
    }
}

export default Template;