import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

export default function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, open, preview } = props;

    function handleClose() {
        onClose();
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <img
                className="content"
                src={"data:image/png;base64," + preview}
                alt="Random first slide"/>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    preview: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};
