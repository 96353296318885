import React, {Component} from 'react';
import { Route,HashRouter } from 'react-router-dom'
import Template from "./components/template/Template";
import RedirectOldPath from "./components/RedirectOldPath";
import Home from "./components/home/Home";
import CertificateList from "./components/certificate/List";
import CertificateBlackList from "./components/certificate/BlackList";
import CertificateDetail from "./components/certificate/Details";
import JudgmentList from "./components/judgment/List";
import JudgmentDetail from "./components/judgment/Details";
import LoginForm from "./components/login/Form";


export const EVENT_APP_GROWL_MESSAGE = "EVENT_APP_GROWL_MESSAGE"

class App extends Component {



    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div>
                <HashRouter>
                    <Template/>
                    <div style={{paddingTop: '45px'}} align="center" >
                        <div style={{maxWidth: "1280px", padding: "15px"}} align="left">
                            <Route exact path="/*" component={RedirectOldPath}/>
                            <Route exact strict path="/home" component={Home} />
                            <Route exact strict path="/certificates" component={CertificateList} />
                            <Route exact strict path="/blackCertificates" component={CertificateBlackList} />
                            <Route exact strict path="/certificates/id/:uuid" component={CertificateDetail} />
                            <Route exact strict path="/certificates/create" component={CertificateDetail} />
                            <Route exact strict path="/judgments" component={JudgmentList} />
                            <Route exact strict path="/judgments/id/:uuid" component={JudgmentDetail} />
                            <Route exact strict path="/judgments/create" component={JudgmentDetail} />
                            <Route exact strict path="/login" component={LoginForm} />

                        </div>
                    </div>
                </HashRouter>
            </div>
        )
    }
}

export default App;
