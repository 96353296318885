import EvenEmitter from "../components/EvenEmitter";
import axios from "axios";
import {EVENT_APP_GROWL_MESSAGE} from "../App";

const LOCALHOST = 'localhost:3000'
const CONTEXT = window.location.port==="3000" ? window.location.protocol+"//"+LOCALHOST : window.location.protocol+"//"+window.location.host;


export const LOGIN_PUB = '/api/pub/login'
export const LOGIN_PRV = '/api/prv/login'
export const ACCOUNT_PUB = '/api/pub/accounts'
export const ACCOUNT_PRV = '/api/prv/accounts'
export const CERTIFICATE_PUB = '/api/pub/certificates'
export const CERTIFICATE_PRV = '/api/prv/certificates'
export const BLACK_CERTIFICATE_PUB = '/api/pub/blackCertificates'
export const JUDGMENT_PUB = '/api/pub/judgments'
export const JUDGMENT_PRV = '/api/prv/judgments'

export const API = {
    login: {
        prv: {
            get: (filter) => _GET(LOGIN_PRV + '/', filter),
            getAccount: (filter) => _GET(LOGIN_PRV + '/account', filter),
        },
        pub: {
            post: (body) => _POST(LOGIN_PUB + '/', JSON.stringify(body))
        }
    },
    account: {
        prv: {
            get: (filter) => _GET(ACCOUNT_PRV + '/', filter),
            getOne: (uuid) => _GET(ACCOUNT_PRV + '/' + uuid),
            getLogin: (filter) => _GET(ACCOUNT_PRV + '/login', filter),
            post: (body) => _POST(ACCOUNT_PRV, JSON.stringify(body)),
            delete: (uuid) => _DELETE(ACCOUNT_PRV + '/' + uuid, null),
        },
        pub: {
            postLogin: (body) => _POST(ACCOUNT_PUB + '/login', JSON.stringify(body)),
            portForgotPassword: (body) => _POST(ACCOUNT_PUB + '/forgotPassword', JSON.stringify(body)),
            postRegistration: (body) => _POST(ACCOUNT_PUB + '/registration', JSON.stringify(body))

        }
    },
    certificate: {
        prv: {
            get: (filter) => _GET(CERTIFICATE_PRV + '/', filter),
            delete: (uuid) => _DELETE(CERTIFICATE_PRV + '/'+uuid, null),
            deleteContent: (uuid) => _DELETE(CERTIFICATE_PRV + '/content/'+uuid, null),
            post: (body) => _POST(CERTIFICATE_PRV, JSON.stringify(body)),
            upload: (file, id) => _UPLOAD('/api/prv/certificates/content/upload', file, id),
            download: (id) => _DOWNLOAD('/api/pub/certificates/content/download/' + id + '.pdf')
        },
        pub: {
            getOne: (uuid) => _GET(CERTIFICATE_PUB + '/' + uuid, null),
            getContent: (uuid) => _GET(CERTIFICATE_PUB + '/content/previews/' + uuid, null),
            getCounts: (filter) => _GET(CERTIFICATE_PUB + '/counts', filter)
        }
    },
    blackCertificate: {
        prv: {
        },
        pub: {
            get: (filter) => _GET(BLACK_CERTIFICATE_PUB + '/', filter),
        }
    },
    judgment: {
        prv: {
            get: (filter) => _GET(JUDGMENT_PRV + '/', filter),
            delete: (uuid) => _DELETE(JUDGMENT_PRV + '/'+uuid, null),
            deleteContent: (uuid) => _DELETE(JUDGMENT_PRV + '/content/'+uuid, null),
            post: (body) => _POST(JUDGMENT_PRV, JSON.stringify(body)),
            upload: (file, id) => _UPLOAD('/api/prv/judgments/content/upload', file, id),
            download: (id) => _DOWNLOAD('/api/pub/judgments/content/download/' + id + '.pdf')
        },
        pub: {
            get: (filter) => _GET(JUDGMENT_PUB + '/', filter),
            getOne: (uuid) => _GET(JUDGMENT_PUB + '/' + uuid, null),
            getContent: (uuid) => _GET(JUDGMENT_PUB + '/content/previews/' + uuid, null),
            getCounts: (filter) => _GET(JUDGMENT_PUB + '/counts', filter),
        }
    }
}


export const isLogin =  localStorage.getItem("jwtToken") === null ? false: true;
export const isAdmin = localStorage.getItem("role") === 'admin' ? true: false;
export const isMember = localStorage.getItem("role") === 'member' ? true: false;
export const isUser = localStorage.getItem("role") === 'user' ? true: false;



function RELOG() {
    if(localStorage.getItem('jwtToken') != null) {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` }
        };
        axios.get(CONTEXT + '/api/prv/login', config).then(response => {
            localStorage.setItem("jwtToken", response.data);
            localStorage.setItem("jwtTokenMinutes", "59");
            localStorage.setItem("jwtTokenSeconds", "59");
        }).catch(processError);
    }
}

export const _GET = (url, filter) => axios.get(CONTEXT + url + convertFilter(filter), createConfig(url)).then().catch(processError)
export const _POST = (url, body) => axios.post(CONTEXT + url, body, createConfig(url)).then().catch(processError)
export const _DELETE = (url) => axios.delete(CONTEXT + url, createConfig(url)).then().catch(processError)

function createConfig(url) {
    let config = {
        headers: {
            'Content-Type':'application/json',
        }}
    if(url.startsWith('/api/prv/')){
        //RELOG()
        config = {
            headers: {
                'Content-Type':'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwtToken')}` }
        };
    }
    return config
}

function convertFilter(filter) {
    let queryFilter = ''
    if(filter != null) {
        Object.keys(filter).forEach((key) => (filter[key] == null || filter[key] == '') && delete filter[key]);
        queryFilter = '?' + new URLSearchParams(filter).toString()
    }
    return queryFilter
}

function processError(error) {
    if(error.response.status == 401) {
        localStorage.clear()
        window.location.href = "/";
    } else {
        let detail = error.response.data
        detail.error = 'HTTP Error'
        detail.status =  error.response.status
        EvenEmitter.dispatch("NOTIFICATION_HTTP_ERROR_RESPONSE", detail)
    }
    return Promise.reject(error)
}

export function _UPLOAD(url, file, id) {
    const data = new FormData();
    data.append('file', file);
    data.append('id', id);

    return fetch(url, {
        method: 'post',
        headers: {
            'Authorization':  'Bearer ' + localStorage.getItem('jwtToken')},
        body: data
    })
}

export function _DOWNLOAD(url) {
    fetch(url)
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'download.pdf';
                a.click();
            });
            //window.location.href = response.url;
        });
}