import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField/TextField";
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Button from "@material-ui/core/Button/Button";

import PreviewsDialog from '../PreviewsDialog'
import {Link} from "react-router-dom";
import EvenEmitter from "../EvenEmitter";
import {API, isAdmin, isLogin, isMember} from "../../api/Api";
import MESSAGES from "./Messages";

class Details extends Component {

    constructor(props){
        super(props);
        this.state = {
            create: false,
            open: false,
            data: {
                id: Number,
                certificateId: String,
                testInstitute: String,
                issuedFor: String,
                modelName: String,
                proofOfInformation: String,
                testifiedDate: String,
                status: String,
                common: false,
                blockCommonLink: false,
                content: null,
                blackList: false,
                contentAvaileble: Boolean,
            },
            previews: []};
    }

    componentDidMount(){
        if(this.props.match.params.uuid == null){
            this.setState({create: true});
        } else {
            this.load()
        }
    }

    load() {
        API.certificate.pub.getOne(this.props.match.params.uuid)
            .then( response => {
                this.setState({data: response.data});

                if(this.state.data.contentAvaileble) {
                    API.certificate.pub.getContent(this.props.match.params.uuid)
                        .then( response2 => {
                            this.setState(response2.data);
                        })
                        .catch(console.log);
                }
            })
            .catch(console.log);
    }

    render() {

        if (!isAdmin && !isMember && this.state.data.blockCommonLink) {
            return (
                <div>
                    <div>Dieser Link ist nur für angemeldete Mitglieder sichtbar!</div>
                    <div>This link is only visible for registered members!</div>
                </div>
            )
        } else {
            return (
                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={3}>
                            {this.state.previews.map(preview => {
                                return <div>
                                    <img
                                        className="content"
                                        src={"data:image/png;base64," + preview.content}
                                        alt="Random first slide"
                                        onClick={() => {
                                            preview.show = true;
                                            this.setState({open: true});
                                        }}/>
                                    <PreviewsDialog
                                        open={preview.show}
                                        preview={preview.content}
                                        onClose={() => {
                                            preview.show = false;
                                            this.setState({open: false});
                                        }} />
                                </div>
                            })}
                            { isAdmin && this.state.data.contentAvaileble === false && (
                                <input type="file" name="file" onChange={(event => {
                                    API.certificate.prv.upload(event.target.files[0], this.state.data.id).then( response => {
                                        EvenEmitter.dispatch("NOTIFICATION_SUCCESS_MESSAGE", {message: 'Dein Image wurde erfolgreich hochgeladen!'})
                                        this.load()
                                    })
                                })}/>
                            )}

                            { isAdmin && this.state.data.contentAvaileble === true && (
                                <Button onClick={() => {
                                    const r = window.confirm( 'Are you sure you want to remove Content?' );
                                    if (r === true) {
                                        API.certificate.prv.deleteContent(this.state.data.id).then(response => {
                                            EvenEmitter.dispatch("NOTIFICATION_SUCCESS_MESSAGE", {message: 'Dein Image wurde erfolgreich entfernt!'})
                                            this.load()
                                        })
                                    }
                                }}>{MESSAGES.delete}</Button>
                            )}

                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Grid container spacing={1}>
                                {this.state.create == false && (
                                    <Grid item xs={12}>
                                        <TextField
                                            id="textFieldId"
                                            disabled={true}
                                            className="valueComponent"
                                            label="Id"
                                            value={this.state.data.id}
                                        />
                                    </Grid>
                                )}
                                {this.state.create == false && (
                                    <Grid item xs={12}>
                                        <TextField
                                            id="textFieldShareLink"
                                            disabled={true}
                                            className="valueComponent"
                                            label="Share Link"
                                            value={"https://certificate.degeuk.org/#/certificates/id/" + this.state.data.id}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="textFieldJustice"
                                        disabled={!isAdmin}
                                        className="valueComponent"
                                        label="Certificate-ID"
                                        value={this.state.data.certificateId}
                                        onChange={(e) => {
                                            this.state.data.certificateId = e.target.value
                                            this.forceUpdate()
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="textFieldJusticeReferenceNumber"
                                        disabled={!isAdmin}
                                        className="valueComponent"
                                        label="Testinstitut"
                                        value={this.state.data.testInstitute}
                                        onChange={(e) => {
                                            this.state.data.testInstitute = e.target.value
                                            this.forceUpdate()
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="textFieldDate"
                                        disabled={!isAdmin}
                                        className="valueComponent"
                                        label="Ausgestellt für"
                                        value={this.state.data.issuedFor}
                                        onChange={(e) => {
                                            this.state.data.issuedFor = e.target.value
                                            this.forceUpdate()
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="textFieldShortText"
                                        disabled={!isAdmin}
                                        className="valueComponent"
                                        label="Modellname"
                                        value={this.state.data.modelName}
                                        onChange={(e) => {
                                            this.state.data.modelName = e.target.value
                                            this.forceUpdate()
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="textFieldLongText"
                                        disabled={!isAdmin}
                                        className="valueComponent"
                                        label="Informationsnachweis"
                                        multiline
                                        value={this.state.data.proofOfInformation}
                                        onChange={(e) => {
                                            this.state.data.proofOfInformation = e.target.value
                                            this.forceUpdate()
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="testifiedDateTextField"
                                        type="date"
                                        disabled={!isAdmin}
                                        className="valueComponent"
                                        label="Testified Date"
                                        value={this.state.data.testifiedDate}
                                        margin="none"
                                        onChange={(event) => {
                                            this.state.data.testifiedDate = event.target.value;
                                            this.forceUpdate();
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="textField"
                                        disabled={!isAdmin}
                                        className="valueComponent"
                                        label="Status"
                                        value={this.state.data.status}
                                        onChange={(e) => {
                                            this.state.data.status = e.target.value
                                            this.forceUpdate()
                                        }}
                                    />
                                </Grid>
                                {isAdmin && (
                                    <Grid item xs={12} md={6}>
                                        <div
                                            className="MuiFormControl-root MuiTextField-root valueComponent MuiFormControl">
                                            <label
                                                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
                                                data-shrink="true" htmlFor="textFieldJustice">Öffentlich</label>
                                            <div
                                                className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                                                <Switch
                                                    id="switchCommon"
                                                    checked={this.state.data.common}
                                                    onChange={(e) => {
                                                        this.state.data.common = e.target.checked
                                                        this.forceUpdate()
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {isAdmin && (
                                    <Grid item xs={12} md={6}>
                                        <div
                                            className="MuiFormControl-root MuiTextField-root valueComponent MuiFormControl">
                                            <label
                                                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
                                                data-shrink="true" htmlFor="textFieldJustice">Öffentliche Link sperren</label>
                                            <div
                                                className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                                                <Switch
                                                    id="switchBlockCommonLink"
                                                    checked={this.state.data.blockCommonLink}
                                                    onChange={(e) => {
                                                        this.state.data.blockCommonLink = e.target.checked
                                                        this.forceUpdate()
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {isAdmin && (
                                    <Grid item xs={12} md={6}>
                                        <div
                                            className="MuiFormControl-root MuiTextField-root valueComponent MuiFormControl">
                                            <label
                                                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
                                                data-shrink="true" htmlFor="textFieldJustice">Black List</label>
                                            <div
                                                className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                                                <Switch
                                                    id="switchBlackList"
                                                    checked={this.state.data.blackList}
                                                    onChange={(e) => {
                                                        this.state.data.blackList = e.target.checked
                                                        this.forceUpdate()
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                            <div style={{float: "right"}}>
                                <Link to={'/certificates'} style={{textDecoration: 'none'}}><Button>{MESSAGES.cancel}</Button></Link>
                                {this.state.create == true && (
                                    <div style={{display: 'inline'}}>
                                        <Button onClick={() => {
                                            console.log(this.state.data);
                                            API.certificate.prv.post(this.state.data).then(response => {
                                                if(response != null) {
                                                    this.setState({data: response.data});
                                                    window.location.href = '/#/certificates/id/' + response.data.id;
                                                    EvenEmitter.dispatch("NOTIFICATION_SUCCESS_MESSAGE", {message: 'Dein Zertifikat wurde erfolgreich gespeichert!'})
                                                }
                                            })
                                        }}>{MESSAGES.save}</Button>
                                    </div>
                                )}

                                {((isAdmin || isMember) && this.state.data.contentAvaileble === true) && this.state.create == false && this.state.data.contentAvaileble && (
                                    <div style={{display: 'inline'}}>
                                        <Button  onClick={() => {
                                            API.certificate.prv.download(this.state.data.id)
                                        }}>{MESSAGES.download}</Button>
                                    </div>
                                )}

                                {isAdmin && this.state.create == false && (
                                    <div style={{display: 'inline'}}>
                                        <Button onClick={() => {
                                            const r = window.confirm( 'Are you sure you want to delete?' );
                                            if (r === true) {
                                                API.certificate.prv.delete(this.state.data.id).then(response => {
                                                    EvenEmitter.dispatch("NOTIFICATION_SUCCESS_MESSAGE", {message: 'Dein Zertifikat wurde erfolgreich gelöscht!'})
                                                    window.location.href = '/#/certificates';
                                                })
                                            }
                                        }}>{MESSAGES.delete}</Button>
                                        <Button onClick={() => {
                                            API.certificate.prv.post(this.state.data).then(response => {
                                                if(response != null) {
                                                    this.setState({data: response.data});
                                                    window.location.href = '/#/certificates/id/' + response.data.id;
                                                    EvenEmitter.dispatch("NOTIFICATION_SUCCESS_MESSAGE", {message: 'Dein Zertifikat wurde erfolgreich gespeichert!'})
                                                }
                                            })
                                        }}>{MESSAGES.update}</Button>
                                    </div>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </div>

            );
        }


    }
}

export default Details;